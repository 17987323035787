import { ROUTE } from '~/constants/routes'
import AuthenticationService from '~/services/AuthenticationService'

export default defineNuxtRouteMiddleware(async () => {
  const { isLoggedIn } = AuthenticationService.isLoggedIn()

  if (isLoggedIn) {
    return await navigateTo(ROUTE.ROOT)
  }
})
